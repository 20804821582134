import React from "react";
import Root from "../components/Root";
import SEO from "../components/seo";
import TutorialsPage from "../components/Tutorials/TutorialsPage";

const Tutorials = () => {
  return (
    <Root>
      <SEO title="Tutorials" />
      <TutorialsPage />
    </Root>
  );
};

export default Tutorials;
