import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import useIntersectionObserver from "./Hooks/UseIntersectionObserver";

const useStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.background.default,
    position: "relative",
    paddingBottom: ({ padding }) => {
      return `${padding}%`;
    },
    overflow: "hidden",
    boxShadow:
      "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px",
    border: "2px solid",
  },
  video: {
    height: "100%",
    width: "100%",
    overflow: "hidden",
    margin: "0 auto",
    transition: "opacity 400ms ease 0ms",
  },
  example: {
    padding: 0,
    display: "flex",
    maxWidth: "100%",
    boxShadow:
      "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px",
    margin: "0px auto 128px",
  },
}));

const VideoWrapper = props => {
  const classes = useStyles(props);
  const { videoId, autoplay, controls } = props;

  const ref = useRef();
  const [isVisible, setIsVisible] = useState(false);

  useIntersectionObserver({
    target: ref,
    onIntersect: ([{ isIntersecting }], observerElement) => {
      if (isIntersecting) {
        setIsVisible(true);
        observerElement.unobserve(ref.current);
      }
    },
  });

  return (
    <div ref={ref} className={classes.root}>
      <div style={{ position: "absolute", width: "100%", height: "100%" }}>
        {isVisible ? (
          <React.Fragment>
            <div style={{ width: "100%", height: "100%" }}>
              <iframe
                title={`https://player.vimeo.com/video/${videoId}`}
                src={`https://player.vimeo.com/video/${videoId}?title=0&byline=0&portrait=0&muted=1&autoplay=${autoplay ? 1 : 0}&controls=${
                  controls ? 1 : 0
                }&loop=1`}
                className={classes.video}
                frameborder="0"
                allow="autoplay; fullscreen"
                allowfullscreen
                dataReady={true}
              ></iframe>
            </div>
          </React.Fragment>
        ) : null}
      </div>
    </div>
  );
};

VideoWrapper.propTypes = {
  padding: PropTypes.number,
  videoId: PropTypes.string,
  autoplay: PropTypes.bool,
  controls: PropTypes.bool,
};

export default VideoWrapper;
