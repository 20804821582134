import React from "react";
import { navigate } from "gatsby";
import { makeStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import VideoWrapper from "../constant/VideoWrapper";

import { trackCustomEvent } from "gatsby-plugin-google-analytics";

const useStyles = makeStyles(theme => ({
  root: {},
  section: {
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
  },
  gridItemVideo: {
    marginBottom: theme.spacing(12),
  },
  mainTitle: {
    fontWeight: "700",
    [theme.breakpoints.down("sm")]: {
      fontSize: "3rem",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "5rem",
    },
  },
  startNowButton: {
    [theme.breakpoints.up("lg")]: {
      fontSize: "1.25rem",
      fontWeight: "500",
    },
  },
}));

const TutorialsPage = props => {
  const classes = useStyles(props);

  const handleStartNow = (e, label) => {
    trackCustomEvent({
      category: "Start Now",
      action: "Click",
      label: label,
      value: 0,
    });

    navigate("https://studio.app-mockup.com");
  };

  return (
    <div className={classes.root}>
      <div className={classes.section}>
        <Container maxWidth="lg">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div style={{ textAlign: "center" }}>
                <Typography className={classes.mainTitle} variant="h2">
                  Tutorials
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div style={{ margin: "10px auto", maxWidth: 600, textAlign: "center" }}>
                <Typography variant="h6">
                  Want to start creating screenshots but don't know where to begin? These tutorials should cover everything you need to know
                  - from navigation the interface, to adding panoramic images and more.
                </Typography>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>

      <div className={classes.section}>
        <Container maxWidth="lg">
          <Grid container spacing={0}>
            <Grid item xs={12} style={{ marginBottom: 32 }}>
              <Grid container direction="row" justify="space-between" alignItems="center">
                <Grid item xs={6}>
                  <Typography variant="h4">
                    <strong>Set Screenshot Inspector Position</strong>
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    className={classes.startNowButton}
                    size="large"
                    color="primary"
                    variant="contained"
                    disableElevation
                    onClick={e => handleStartNow(e, "Examples")}
                    style={{ float: "right" }}
                  >
                    Start Now
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid className={classes.gridItemVideo} item xs={12}>
              <VideoWrapper padding={52.66} videoId="470318813" autoplay={false} controls={true} />
            </Grid>

            <Grid item xs={12} style={{ marginBottom: 32 }}>
              <Grid container direction="row" justify="space-between" alignItems="center">
                <Grid item xs={6}>
                  <Typography variant="h4">
                    <strong>Update Screenshot Preview Size</strong>
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    className={classes.startNowButton}
                    size="large"
                    color="primary"
                    variant="contained"
                    disableElevation
                    onClick={e => handleStartNow(e, "Examples")}
                    style={{ float: "right" }}
                  >
                    Start Now
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid className={classes.gridItemVideo} item xs={12}>
              <VideoWrapper padding={52.66} videoId="470319037" autoplay={false} controls={true} />
            </Grid>

            <Grid item xs={12} style={{ marginBottom: 32 }}>
              <Grid container direction="row" justify="space-between" alignItems="center">
                <Grid item xs={6}>
                  <Typography variant="h4">
                    <strong>Add, remove, duplicate, reset, reorder screenshots</strong>
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    className={classes.startNowButton}
                    size="large"
                    color="primary"
                    variant="contained"
                    disableElevation
                    onClick={e => handleStartNow(e, "Examples")}
                    style={{ float: "right" }}
                  >
                    Start Now
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid className={classes.gridItemVideo} item xs={12}>
              <VideoWrapper padding={52.66} videoId="470316487" autoplay={false} controls={true} />
            </Grid>

            <Grid item xs={12} style={{ marginBottom: 32 }}>
              <Grid container direction="row" justify="space-between" alignItems="center">
                <Grid item xs={6}>
                  <Typography variant="h4">
                    <strong>Add, remove, update current device</strong>
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    className={classes.startNowButton}
                    size="large"
                    color="primary"
                    variant="contained"
                    disableElevation
                    onClick={e => handleStartNow(e, "Examples")}
                    style={{ float: "right" }}
                  >
                    Start Now
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid className={classes.gridItemVideo} item xs={12}>
              <VideoWrapper padding={52.66} videoId="470316792" autoplay={false} controls={true} />
            </Grid>

            <Grid item xs={12} style={{ marginBottom: 32 }}>
              <Grid container direction="row" justify="space-between" alignItems="center">
                <Grid item xs={6}>
                  <Typography variant="h4">
                    <strong>Update Screenshot Background</strong>
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    className={classes.startNowButton}
                    size="large"
                    color="primary"
                    variant="contained"
                    disableElevation
                    onClick={e => handleStartNow(e, "Examples")}
                    style={{ float: "right" }}
                  >
                    Start Now
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid className={classes.gridItemVideo} item xs={12}>
              <VideoWrapper padding={52.66} videoId="470317722" autoplay={false} controls={true} />
            </Grid>

            <Grid item xs={12} style={{ marginBottom: 32 }}>
              <Grid container direction="row" justify="space-between" alignItems="center">
                <Grid item xs={6}>
                  <Typography variant="h4">
                    <strong>Add & Update panoramic image</strong>
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    className={classes.startNowButton}
                    size="large"
                    color="primary"
                    variant="contained"
                    disableElevation
                    onClick={e => handleStartNow(e, "Examples")}
                    style={{ float: "right" }}
                  >
                    Start Now
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid className={classes.gridItemVideo} item xs={12}>
              <VideoWrapper padding={52.66} videoId="470317099" autoplay={false} controls={true} />
            </Grid>

            <Grid item xs={12} style={{ marginBottom: 32 }}>
              <Grid container direction="row" justify="space-between" alignItems="center">
                <Grid item xs={6}>
                  <Typography variant="h4">
                    <strong>Update screenshot title</strong>
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    className={classes.startNowButton}
                    size="large"
                    color="primary"
                    variant="contained"
                    disableElevation
                    onClick={e => handleStartNow(e, "Examples")}
                    style={{ float: "right" }}
                  >
                    Start Now
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid className={classes.gridItemVideo} item xs={12}>
              <VideoWrapper padding={52.66} videoId="470319180" autoplay={false} controls={true} />
            </Grid>

            <Grid item xs={12} style={{ marginBottom: 32 }}>
              <Grid container direction="row" justify="space-between" alignItems="center">
                <Grid item xs={6}>
                  <Typography variant="h4">
                    <strong>Update screenshot device</strong>
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    className={classes.startNowButton}
                    size="large"
                    color="primary"
                    variant="contained"
                    disableElevation
                    onClick={e => handleStartNow(e, "Examples")}
                    style={{ float: "right" }}
                  >
                    Start Now
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid className={classes.gridItemVideo} item xs={12}>
              <VideoWrapper padding={52.66} videoId="470318226" autoplay={false} controls={true} />
            </Grid>

            <Grid item xs={12} style={{ marginBottom: 32 }}>
              <Grid container direction="row" justify="space-between" alignItems="center">
                <Grid item xs={6}>
                  <Typography variant="h4">
                    <strong>Preview Screenshots</strong>
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    className={classes.startNowButton}
                    size="large"
                    color="primary"
                    variant="contained"
                    disableElevation
                    onClick={e => handleStartNow(e, "Examples")}
                    style={{ float: "right" }}
                  >
                    Start Now
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid className={classes.gridItemVideo} item xs={12}>
              <VideoWrapper padding={52.66} videoId="470317580" autoplay={false} controls={true} />
            </Grid>

            <Grid item xs={12} style={{ marginBottom: 32 }}>
              <Grid container direction="row" justify="space-between" alignItems="center">
                <Grid item xs={6}>
                  <Typography variant="h4">
                    <strong>Export Screenshots</strong>
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    className={classes.startNowButton}
                    size="large"
                    color="primary"
                    variant="contained"
                    disableElevation
                    onClick={e => handleStartNow(e, "Examples")}
                    style={{ float: "right" }}
                  >
                    Start Now
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid className={classes.gridItemVideo} item xs={12}>
              <VideoWrapper padding={52.66} videoId="470330458" autoplay={false} controls={true} />
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
  );
};

TutorialsPage.propTypes = {};

export default TutorialsPage;
